import React, { useEffect } from "react";
import "./style.css";
import "./App.css";
import Navbar from "./Components/navbar";
import Footer from "./Components/footer";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import HomePage from "./screens/landing";
import Services from "./screens/services";
import { scroller } from "react-scroll";
import ScrollToTop from "./Components/scrollTop";

function App() {
  useEffect(() => {
    scroller.scrollTo('navbar', {
      duration: 100, // Adjust the duration as needed
      delay: 10,
      smooth: true,
    });
  }, []);
  return (
    <BrowserRouter>
      <div className="mx-auto flex flex-col relative ">
        <ScrollToTop/>
        <Navbar />
        <Routes>
          <Route path="/" Component={HomePage} />
          <Route path="/services" Component={Services}/>
        </Routes>
        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;
