import React from "react";
import ArrowUpSVG from "../../assets/icons/arrow_up_circle.svg";
import { scroller } from "react-scroll";

const ScrollToTop = () => {
  const scrollHandler = () => {
    scroller.scrollTo("navbar", {
      duration: 100, // Adjust the duration as needed
      delay: 10,
      smooth: true,
    });
  };
  return (
    <div className="fixed right-10 bottom-10 z-50">
      <img
        src={ArrowUpSVG}
        alt=""
        className="w-10 h-10"
        onClick={scrollHandler}
      />
    </div>
  );
};

export default ScrollToTop;
