import React from 'react'
import TechImg1 from '../../assets/img/techImg1.svg'
import RightArrow from '../../assets/icons/right_arrow_black.svg'

const TechCard = ({item}) => {
    return (
        <div className='flex flex-col gap-y-3 p-5 min-w-[317px] h-max border border-[#528CF3] rounded-[3.516px]'>
            <img className="pt-2 w-[42px] h-[42px]" src={item?.logo} alt="" />
            <p className='text-blue-head font-bold text-[18.171px] '>{item?.title}</p>
            <p className='text-[#3D5669] text-[14px] '>{item?.description}</p>
            <div className='flex items-center gap-x-2'>
            <p className='text-black font-bold text-[12px]'>Learn More </p>
            <img className="w-[20px] h-[12px]" src={RightArrow} alt="" />
            </div>
        </div>
    )
}

export default TechCard