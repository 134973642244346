import Tech1 from '../../assets/img/techImg1.svg'
import Tech2 from '../../assets/img/techImg2.svg'
import Tech3 from '../../assets/img/techImg3.svg'
import Tech4 from '../../assets/img/techImg4.svg'
import Tech5 from '../../assets/img/techImg5.svg'
import Tech6 from '../../assets/img/techImg6.svg'
import Tech7 from '../../assets/img/techImg7.svg'
import Tech8 from '../../assets/img/techImg8.svg'
import Tech9 from '../../assets/img/techImg9.svg'

export const techCards = [
    {id: 1, logo: Tech1, title: 'Software Development', description: `Get the custom software you need to succeed in your business. Our solutions are tailored to your specific needs and help you achieve your goals today and in the future.`},
    {id: 2, logo: Tech3, title: 'Web Development', description: `We build custom web applications that meet your unique business needs. Our team of experts uses state-of-the-art technologies and development practices to create applications that are both functional and visually appealing.`},
    {id: 3, logo: Tech6, title: 'Mobile App Development', description: `We build native and hybrid mobile apps for iOS and Android using the latest tech stack. Our expertise spans fintech, edtech, healthcare, streaming, and other industries.`},
    {id: 4, logo: Tech7, title: 'Full Stack Development', description: `Build interactive, dynamic, and feature-rich applications for any platform with our full-stack developers' expertise in front-end, back-end, MEAN stack, UI, and server infrastructure.`},
    {id: 5, logo: Tech8, title: 'UI & UX Design', description: `Are you looking for a design team that is transparent, efficient, and delivers results? Look no further than our long-standing team of experts in corporate identity design, responsive web solutions, print design, motion graphics, and UX strategy and consulting.`},
    {id: 6, logo: Tech9, title: 'Product Engineering', description: `Stay ahead of the competition, drive innovation, and expand into new markets with our product engineering solutions. We help organizations integrate new-age technology into their software portfolios, and provide product engineering consulting, new product development, product modernization, product testing, and support and maintenance services.`},
    {id: 7, logo: Tech2, title: 'DevOps & Cloud', description: `Leverage our on-demand DevOps expertise to achieve cloud-enabled business transformation that will save you costs, increase agility, speed up delivery, and drive innovation.`},
    {id: 8, logo: Tech4, title: 'Blockchain Development', description: `We provide comprehensive blockchain development services to help businesses grow in the cryptocurrency, NFT marketplace, smart contract, ICO, DeFi, and dApp industries.`},
    {id: 9, logo: Tech5, title: 'Digital Transformation', description: `Our digital transformation solutions help enterprises assess their digital transformation readiness, develop the right DX framework, and induce digital capabilities using analytics, automation, and AI. We help businesses modernize their business models and processes for improved customer experiences.`},
]