import React, { useEffect, useState } from "react";
import { Drawer } from "@material-tailwind/react";
import { Link } from "react-router-dom";
import Logo from "../../assets/img/logo.svg";

const DrawerComponent = ({ open, setOpen, drawerList = [], scrollToSection }) => {
  const closeDrawer = () => setOpen(false);
  const [arrayItems, setArrayItems] = useState([]);

  useEffect(() => {
    if (drawerList?.length > 0) {
      setArrayItems([...drawerList]);
    }
  }, [drawerList]);

  return (
    <Drawer open={open} onClose={closeDrawer} className="drawer-main h-full z-10">

        {/* drawer header */}
      <div className=" flex items-center justify-between py-6 px-3 border-[#ccc] border-b">
        <Link to={"/"}>
          <img className="h-8 w-auto" src={Logo} alt="Your Company" />
        </Link>
        <div onClick={closeDrawer}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="currentColor"
            className="h-5 w-5"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </div>
      </div>

      {/* drawer body */}
      <div className="flex flex-col">
        {arrayItems?.length > 0 &&
          arrayItems?.map((item, ind) => {
            return (
              <p key={item?.slug} className="border-[#f6f6f6] border-b text-sm uppercase items-center font-semibold p-4 text-black hover:bg-red-f0 hover:text-white" onClick={()=> {scrollToSection(item?.slug); closeDrawer()}}>
                {item?.title}
              </p>
            );
          })}
      </div>
    </Drawer>
  );
};

export default DrawerComponent;
