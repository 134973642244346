import React, { useState } from "react";
import CalendarIcon from "../../assets/icons/calendar.svg";
import UnlockIcon from "../../assets/icons/unlock.svg";
import axios from "axios";


const BookCallCard = () => {
  const [bookingData, setBookingData] = useState({
    name: "",
    email: "",
    phone: ""
  });

  console.log("cehc", bookingData);
  const handleChange = (e) => {
    const {name, value} = e.target;
    setBookingData({...bookingData, [name]: value})
  };

  const bookCallHandler = async(e) =>{
    e.preventDefault();
    console.log('booking data:', bookingData)

    try {
      const res = await axios.post('../../mail.php', bookingData)
      console.log("🚀 ~ file: BookCallCard.js:26 ~ bookCallHandler ~ res:", res)
    } catch (error) {
      console.log("🚀 ~ file: BookCallCard.js:29 ~ bookCallHandler ~ error:", error)
    }
  }
  return (
    <div className="flex flex-col gap-y-5 items-center py-6 px-10 rounded-[34px] bg-blue-head bg-opacity-90">
      <p className="text-2xl italic font-medium text-white">Start Here</p>
      <div className="flex flex-col gap-y-4 w-full">
        <input
          name="name"
          placeholder="Enter your name"
          className="w-full text-sm text-white border bg-transparent placeholder:text-white border-white rounded-[5px] px-4 py-2.5"
          onChange={(e) => handleChange(e)}
        />
        <input
          name="email"
          placeholder="Your Email "
          className="w-full text-sm text-white border bg-transparent placeholder:text-white border-white rounded-[5px] px-4 py-2.5"
          onChange={(e) => handleChange(e)}
        />
        <input
          name="phone"
          placeholder="Phone number (optional)"
          className="w-full text-sm text-white border bg-transparent placeholder:text-white border-white rounded-[5px] px-4 py-2.5"
          onChange={(e) => handleChange(e)}
        />
        <a
          className="px-3"
          href="mailto:ranaahtisham24@gmail.com?subject='Hello from Abstract!'&bodyq='Just popped in to say hello'"
        >
          <button className="w-full p-3 rounded-[7px] bg-red-f0 text-white text-sm font-semibold" onClick={(e)=> bookCallHandler(e)}>
            BOOK A CALL
          </button>
        </a>
        <p className="text-sm font-bold underline text-[#0641A2]">
          Or, Use this form to tell us about your needs.
        </p>

        <div className="flex justify-evenly text-white">
          <div className="flex flex-col gap-y-2">
            <img src={CalendarIcon} alt="" className="w-[20px] h-[20px] ml-1" />
            <p className="text-sm font-bold">
              Pick a date & time of
              <br />
              You choice.
            </p>
          </div>
          <div className="flex flex-col gap-y-2">
            <img src={UnlockIcon} alt="" className="w-[20px] h-[20px] ml-1" />
            <p className="text-sm font-bold">
              No obligation.
              <br />
              Cancel anytime.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookCallCard;
