import React from 'react'
import Hero from '../../Components/hero'
import DigitalEngineering from '../../Components/digitalEngineering'
import About from '../../Components/about'
import Portfolio from '../../Components/portfolio'
import Technology from '../../Components/technology'
// import Blogs from '../../Components/blogs'
import FrequentlyAskedQuestions from '../../Components/faqs'
import PricePlan from '../../Components/pricePlan'

const HomePage = () => {
  return (
     <div >
      <Hero />
      <DigitalEngineering />
      <About />
      <Portfolio />
      <Technology />
      {/* <Blogs /> */}
      <FrequentlyAskedQuestions />
      <PricePlan />
    </div>
  )
}

export default HomePage