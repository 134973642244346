

import CloudImg1 from "../../assets/img/technology/cloudImg1.png";
import CloudImg2 from "../../assets/img/technology/cloudImg2.png";
import CloudImg3 from "../../assets/img/technology/cloudImg3.png";
import CloudImg4 from "../../assets/img/technology/cloudImg4.png";

import BackendImg1 from "../../assets/img/technology/backendImg1.png";
import BackendImg2 from "../../assets/img/technology/backendImg2.png";
import BackendImg3 from "../../assets/img/technology/backendImg3.png";
import BackendImg4 from "../../assets/img/technology/backendImg4.png";
import BackendImg5 from "../../assets/img/technology/backendImg5.png";
import BackendImg6 from "../../assets/img/technology/backendImg6.png";

import FrontendImg1 from "../../assets/img/technology/frontendImg1.png";
import FrontendImg2 from "../../assets/img/technology/frontendImg2.png";
import FrontendImg3 from "../../assets/img/technology/frontendImg3.png";
import FrontendImg4 from "../../assets/img/technology/frontendImg4.png";
import FrontendImg5 from "../../assets/img/technology/frontendImg5.png";
import FrontendImg6 from "../../assets/img/technology/frontendImg6.png";
import FrontendImg7 from "../../assets/img/technology/frontendImg7.png";

import DatabaseImg1 from "../../assets/img/technology/databaseImg1.png";
import DatabaseImg2 from "../../assets/img/technology/databaseImg2.png";
import DatabaseImg3 from "../../assets/img/technology/databaseImg3.png";
import DatabaseImg4 from "../../assets/img/technology/databaseImg4.png";
import DatabaseImg5 from "../../assets/img/technology/databaseImg5.png";

import ShoppingImg1 from "../../assets/img/technology/shoppingImg1.png";
import ShoppingImg2 from "../../assets/img/technology/shoppingImg2.png";
import ShoppingImg3 from "../../assets/img/technology/shoppingImg3.png";
import ShoppingImg4 from "../../assets/img/technology/shoppingImg4.png";
import ShoppingImg5 from "../../assets/img/technology/shoppingImg5.png";

import CmsImg1 from "../../assets/img/technology/cmsImg1.png";
import CmsImg2 from "../../assets/img/technology/cmsImg2.png";
import CmsImg3 from "../../assets/img/technology/cmsImg3.png";

export const technologyList = [
  { slug: "front_end", name: "Front-end" },
  { slug: "back_end", name: "Back-end" },
  { slug: "database", name: "Database" },
  { slug: "clouds", name: "Clouds" },
  { slug: "shopping_cart", name: "Shopping Cart" },
  { slug: "cms", name: "CMS" },
];

export const techCards = {
  
  front_end: [
    { slug: "react", img: FrontendImg1 },
    { slug: "angular", img: FrontendImg2 },
    { slug: "html", img: FrontendImg3 },
    { slug: "vue", img: FrontendImg4 },
    { slug: "next", img: FrontendImg5 },
    { slug: "tailwind", img: FrontendImg6 },
    { slug: "bootstrap", img: FrontendImg7 },
  ],
  back_end: [
    { slug: "laravel", img: BackendImg1 },
    { slug: "nodejs", img: BackendImg2 },
    { slug: "rails", img: BackendImg3 },
    { slug: "codeignitor", img: BackendImg4 },
    { slug: "springboot", img: BackendImg5 },
    { slug: "expressjs", img: BackendImg6 },
  ],
  clouds: [
    { slug: "google_cloud", img: CloudImg1 },
    { slug: "aws", img: CloudImg2 },
    { slug: "salesforce", img: CloudImg3 },
    { slug: "azure", img: CloudImg4 },
  ],
  database: [
    { slug: "mysql", img: DatabaseImg1 },
    { slug: "postgresql", img: DatabaseImg2 },
    { slug: "microsoftsql", img: DatabaseImg3 },
    { slug: "mongodb", img: DatabaseImg4 },
    { slug: "redis", img: DatabaseImg5 },
  ],
  shopping_cart: [
    { slug: "woocommerce", img: ShoppingImg1 },
    { slug: "shopify", img: ShoppingImg2 },
    { slug: "salesforce", img: ShoppingImg3 },
    { slug: "magento", img: ShoppingImg4 },
    { slug: "bigcommerce", img: ShoppingImg5 },
  ],
  cms: [
    { slug: "wordpress", img: CmsImg1 },
    { slug: "jumla", img: CmsImg2 },
    { slug: "drupal", img: CmsImg3 },
  ],
};
