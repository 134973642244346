import React,{ useState} from "react";
import TickIcon from "../../assets/icons/tick.svg";
import TickIconWhite from "../../assets/icons/tick_white.svg";
import DialogComponent from '../common/Dialog.js'

const PricePlan = () => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
    <DialogComponent isOpen={isOpen} setIsOpen={setIsOpen} />
    <div className="flex flex-col px-4 sm:px-6 lg:px-8 py-14 w-full bg-gradient-to-b from-[#e6dbd9] to-[#dfc4d3] ">
      <div className="container mx-auto">
        <p className="text-sm font-bold uppercase text-[#001854]">
          Our Engineering Excellence
        </p>
        <p className="text-5xl font-bold text-[#001854] pt-2">ENGAGEMENT PLAN</p>
        <div className="flex flex-col gap-y-4 lg:flex-row gap-x-2 relative bg-[#f3e9e9] backdrop-blur-[12px] p-6 mt-20">
          <div className="lg:w-[50%] flex flex-col gap-y-2">
            <p className="text-[25.84px] font-bold text-blue-dark-head">
              Fixed Price
            </p>
            <p className="text-[17.23px] text-[#231D4F]">
              On time. Within budget. As per expectations
            </p>
            <div className="flex flex-col gap-y-3 py-5">
              <div className="flex gap-x-2 items-center">
                <div className="relative inline-flex items-center justify-center w-5 h-5 p-1 overflow-hidden bg-[#dbcfe2] rounded-full">
                  <img src={TickIcon} alt="" className="" />
                </div>
                <p className="text-[12px] font-medium text-[#848199]">
                  Assess specs to cover all the if’s but’s and what if's
                </p>
              </div>
              <div className="flex gap-x-2 items-center">
                <div className="relative inline-flex items-center justify-center w-5 h-5 p-1 overflow-hidden bg-[#dbcfe2] rounded-full">
                  <img src={TickIcon} alt="" className="" />
                </div>
                <p className="text-[12px] font-medium text-[#848199]">
                Visualize your end product without spending money on development.
                </p>
              </div>
              <div className="flex gap-x-2 items-center">
                <div className="relative inline-flex items-center justify-center w-5 h-5 p-1 overflow-hidden bg-[#dbcfe2] rounded-full">
                  <img src={TickIcon} alt="" className="" />
                </div>
                <p className="text-[12px] font-medium text-[#848199]">
                Focus on your core business while we take care of the technicalities
                </p>
              </div>
              <div className="flex gap-x-2 items-center">
                <div className="relative inline-flex items-center justify-center w-5 h-5 p-1 overflow-hidden bg-[#dbcfe2] rounded-full">
                  <img src={TickIcon} alt="" className="" />
                </div>
                <p className="text-[12px] font-medium text-[#848199]">
                Support via E-mail and Phone
                </p>
              </div>
            </div>
            <button className="w-max px-6 py-2 bg-gradient-to-r from-[#00DEFF] to-[#007FFF] font-medium text-white" onClick={() => setIsOpen(true)}>Get A Quote </button>
          </div>
          <div className="lg:absolute right-8 top-[-24px] lg:w-[50%] flex flex-col gap-y-2 bg-[#001854] p-6 text-white">
            <p className="text-[25.84px] font-bold text-white">
            IT Experts
            </p>
            <p className="text-[17.23px] text-white">
            High-performing, on-demand teams of IT Experts
            </p>
            <div className="flex flex-col gap-y-3 py-5 ">
              <div className="flex gap-x-2 items-center">
                <div className="relative inline-flex items-center justify-center w-5 h-5 p-1 overflow-hidden bg-[#263b6e] rounded-full">
                  <img src={TickIconWhite} alt="" className="" />
                </div>
                <p className="text-[12px] font-medium">
                High-performing, on-demand teams of IT Experts
                </p>
              </div>
              <div className="flex gap-x-2 items-center">
                <div className="relative inline-flex items-center justify-center w-5 h-5 p-1 overflow-hidden bg-[#263b6e] rounded-full">
                  <img src={TickIconWhite} alt="" className="" />
                </div>
                <p className="text-[12px] font-medium">
                Develop a product - working in a true agile fashion.
                </p>
              </div>
              <div className="flex gap-x-2 items-center">
                <div className="relative inline-flex items-center justify-center w-5 h-5 p-1 overflow-hidden bg-[#263b6e] rounded-full">
                  <img src={TickIconWhite} alt="" className="" />
                </div>
                <p className="text-[12px] font-medium">
                Maintain & support your existing product.
                </p>
              </div>
              <div className="flex gap-x-2 items-center">
                <div className="relative inline-flex items-center justify-center w-5 h-5 p-1 overflow-hidden bg-[#263b6e] rounded-full">
                  <img src={TickIconWhite} alt="" className="" />
                </div>
                <p className="text-[12px] font-medium">
                Visualize your end product without spending money on development.
                </p>
              </div>
            </div>
            <button className="w-max px-6 py-2 bg-gradient-to-r from-[#00DEFF] to-[#007FFF] font-medium text-white" onClick={() => setIsOpen(true)}>Augment Your Team</button>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default PricePlan;
