import React, { useState } from "react";
import BannerImg from "../../assets/img/banner-img.png";
import FacebookIcon from "../../assets/icons/facebook_icon.svg";
import YoutubeIcon from "../../assets/icons/youtube_icon.svg";
import LinkedinIcon from "../../assets/icons/linkedin_icon.svg";
import TwitterIcon from "../../assets/icons/twitter_icon.svg";
import DynamicTradingLogo from "../common/DynamicTradingLogo";
import BookCallCard from "../common/BookCallCard";
import DialogComponent from "../common/Dialog";

const Hero = ({ callFrom = "" }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <DialogComponent isOpen={isOpen} setIsOpen={setIsOpen} />
    
    <section id="hero" className="container mx-auto py-12 px-4 sm:px-6 lg:px-8">
      <div className="grid sm:grid-cols-1 sm:place-items-center lg:grid-cols-2  gap-4">
        <div className="w-full h-full flex flex-col justify-between">
          {callFrom == "services" ? (
            <div className="flex flex-col gap-y-4">
              <DynamicTradingLogo
                beforeBr={"Hire Dedicated PHP"}
                afterBr={"Developers"}
                textColor={"#0641A2"}
              />
              <p className="text-blue-dark-para text-md w-[60%]">
                Hire PHP Developers on a full-time, hourly, or fixed cost
                project basis and manage them as your extended team.
              </p>
              <p className="text-[#024E78] font-medium text-sm">
                ISO 9001 & 27001 Certified with over 98% 5-Star Rating
              </p>
            </div>
          ) : (
            <div className="flex flex-col gap-y-4">
              <DynamicTradingLogo
                beforeBr={"Your Digital Engineering"}
                afterBr={"and IT Talent Partner"}
                // textColor={'#0641A2'}
              />
              <ul
                role="list"
                className="grid grid-cols-2 gap-1 list-disc list-inside text-[#767C93] px-2"
              >
                <li>Product Engineering</li>
                <li>Digital Transformation.</li>
                <li>Enterprise Mobility</li>
                <li>Dedicated Teams</li>
                <li>Web Development</li>
              </ul>
              <div className="flex gap-x-4 pt-4">
                <button className="bg-[#F02A2A] font-semibold text-[10px] text-white px-2 md:px-4 py-2 rounded-md" onClick={() => setIsOpen(true)}>
                  BOOK A CALL
                </button>
                <p className="underline text-[#767C93] text-[15px]">
                  Or, Use this form to tell us about your needs.
                </p>
              </div>
            </div>
          )}
          <div className="flex gap-x-6 items-end pt-4 lg:pt-0">
            <div className="flex gap-x-2 items-center">
              <img src={FacebookIcon} alt="" className="" />
              <p className="text-[14px] font-bold text-black">Facebook</p>
            </div>
            <div className="flex gap-x-2 items-center">
              <img src={YoutubeIcon} alt="" className="" />
              <p className="text-[14px] font-bold text-black">Youtube</p>
            </div>
            <div className="flex gap-x-2 items-center">
              <img src={LinkedinIcon} alt="" className="" />
              <p className="text-[14px] font-bold text-black">LinkedIn</p>
            </div>
            <div className="flex gap-x-2 items-center">
              <img src={TwitterIcon} alt="" className="" />
              <p className="text-[14px] font-bold text-black">Twitter</p>
            </div>
          </div>
        </div>

        <div className={`w-full flex items-center ${callFrom == "services" ? 'justify-center': 'justify-end'} `}>
          {callFrom == "services" ? (
            <BookCallCard/>
            // <div className="flex bg-blue-head bg-opacity-90 rounded-[34px] w-full h-[400px]">

            // </div>
          ) : (
            <img className="hidden lg:block" src={BannerImg} alt="Hero Banner" />
          )}
        </div>
      </div>
    </section>
    </>

  );
};

export default Hero;
