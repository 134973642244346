import React, { useState } from "react";
import HeadingCurve from "../../assets/img/heading-curve.svg";
import AboutImg1 from "../../assets/img/about/about_img1.png";
import AboutImg2 from "../../assets/img/about/about_img2.png";
import DotsImg from "../../assets/img/about/dots.svg";
import GreenCircleImg from "../../assets/img/about/green_circle.png";
import ArrowDownIcon from "../../assets/icons/about/arrow_down.svg";
import { aboutGuidelines } from "./data";

const About = () => {
  const [openAcc, setOpenAcc] = useState("respect");
  const handleOpenAcc = (item) => {
    setOpenAcc(item?.slug);
  };
  return (
    <div className="container mx-auto">
    <div id='about' className="flex flex-col gap-y-12 px-4 sm:px-6 lg:px-8 py-12">
      <div className="flex gap-1 flex-col">
        <p className="text-blue-1f font-semibold text-sm">Dynamic Trading </p>
        <img className="w-12" src={HeadingCurve} alt="" />
        <h1 className="text-sm font-bold uppercase text-blue-26">
          FROM IDEAS TO REALITY
        </h1>
        <p className="text-blue-26 font-bold text-5xl pt-2">About Us</p>
      </div>
      <div className="grid sm:grid-cols-1 lg:grid-cols-2 gap-x-4">
        <div className=" flex relative">
          <img
            src={AboutImg1}
            alt=""
            className="w-[49%] h-[362px] drop-shadow-md"
          />
          <div className="w-[49%] flex flex-col gap-y-2 items-start">
            <img
              src={DotsImg}
              alt=""
              className="w-[46.23px] h-[73.33px] drop-shadow-md"
            />
            <img src={AboutImg2} alt="" className="w-full h-[362px]" />
          </div>
          <div
            className="absolute left-28 bottom-28 md:bottom-36 md:left-72 lg:bottom-40 lg:left-40 xl:left-56 xl:bottom-36 w-[140px] h-[140px] flex items-center justify-center"
            style={{
              backgroundImage: `url(${GreenCircleImg})`,
              backgroundRepeat: "no-repeat",
            }}
          >
            <p className="text-white text-[19px] text-center font-extrabold">
              100% <br /> TRUSTED
            </p>
          </div>
        </div>
        <div className=" flex flex-col gap-y-2">
          {aboutGuidelines?.map((item, ind) => {
            return (
              <div
              key={ind}
                onClick={() => handleOpenAcc(item)}
                className={`w-full flex items-start gap-x-4 px-8 py-4 ${
                  openAcc == item?.slug ?
                  "shadow-lg shadow-[#00000014] border-l-[3.87px] border-l-[#0641A2]"
                  :
                  'pl-9'
                } `}
              >
                <div className="flex flex-col items-center">
                  <div
                    className={`w-[44px] h-[44px] p-2 rounded-md ${
                      openAcc == item?.slug ? "bg-[#374590]" : "bg-[#5C6972]"
                    }`}
                  >
                    <img src={item?.icon} alt="" className="w-full h-full" />
                  </div>
                  <img src={ArrowDownIcon} alt="" className="w-[11.18px] h-[61.9px]" />
                </div>
                <div className="flex flex-col gap-y-3">
                  <p
                    className={`text-[14px] font-bold uppercase  ${
                      openAcc == item?.slug ? "text-[#374590]" : "text-black"
                    } ]`}
                  >
                    {item?.title}
                  </p>
                  <p className="text-[11px] font-semibold text-[#5C6972]">
                    {item?.description}
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
    </div>
  );
};

export default About;
