import React, { useEffect, useState } from "react";
import { techCards, technologyList } from "./data";

const Technology = () => {
  const [selectedTab, setSelectedTab] = useState("clouds");
  const [selectedTabItems, setSelectedTabItems] = useState([]);

  const tabHandler = (item) => {
    setSelectedTab(item?.slug);
    for(let key in techCards){
      if(key == item?.slug){
        setSelectedTabItems([...techCards[key]])
      }
    }
  };

  useEffect(() => {
    if (techCards && techCards?.clouds?.length > 0) {
      setSelectedTabItems([...techCards?.clouds]);
    }
  }, []);
  return (
    <div className="container mx-auto">
      <div
        id={"technologies"}
        className="w-full flex flex-col gap-y-2 justify-start items-start px-4 sm:px-6 lg:px-8 py-12"
      >
        <p className="text-sm font-bold uppercase text-blue-dark-head">
          TECHNOLOGY INDEX
        </p>
        <p className="text-5xl font-bold text-[#001854]">Technology</p>
        <p className="text-sm text-[#001854] pt-2">
          What technology we are using for our valued customers
        </p>
        <div className="flex gap-x-1 py-8 flex-wrap">
          {technologyList?.map((item, ind) => {
            return (
              <button
                key={ind}
                onClick={() => tabHandler(item)}
                className={`py-2 px-4 rounded-[5px]  ${
                  item?.slug == selectedTab
                    ? "bg-gradient-to-r from-[#00DEFF] to-[#007FFF]  font-semibold text-white"
                    : "text-[#455963] text-[19.43px]"
                }`}
              >
                {item?.name}
              </button>
            );
          })}
        </div>
        <div className="flex items-start flex-wrap gap-x-4 py-2">
          {selectedTabItems?.map((item, ind) => {
            return (
              <div
                key={ind}
                className="w-max h-[129.94px] flex items-center justify-center rounded-[5px]"
              >
                <img src={item?.img} />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Technology;
