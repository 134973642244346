import { Fragment, useEffect, useState } from "react";
import {
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";
import AccordionItemClose from "../../assets/icons/accordion_vector.svg";
import AccordionItemOpen from "../../assets/icons/accordion_vector_down.svg";

const accordionItems = [
  {
    id: 1,
    heading: "What is the role of software developers?",
    paragraph:
      "Software developers engage in tasks such as data retrieval, storage, and manipulation to assess a system's needs and capabilities. Their responsibilities encompass software design, coding, construction, deployment, and ongoing maintenance, drawing upon diverse skills and tools. Additionally, they collaborate with clients to identify requirements, aid in development, ensure user-friendliness, and conduct thorough quality checks. With a skilled and knowledgeable team of software developers, we provide unparalleled development services to our clients",
    state: "open",
  },
  {
    id: 2,
    heading: "What does the term 'digital transformation' mean?",
    paragraph:
      "Digital transformation empowers businesses to strategically incorporate digital technology across all operational domains, instigating profound shifts and providing customer value. This approach yields advantages such as heightened business flexibility, enhanced operational efficacy, and the unlocking of fresh value from personnel, clients, and stakeholders. Capital Numbers serves as a digital transformation consultancy, furnishing cost-effective digital remedies. With a workforce of over 700 proficient developers, we facilitate swift business metamorphosis, enabling enterprises to capitalize on prospects and ignite innovation and expansion.",
    state: "close",
  },
  {
    id: 3,
    heading: "What makes digital transformation significant?",
    paragraph:
      "Digital transformation revolutionizes an organization's operational landscape. Various motivations drive businesses to embark on this journey, with the most common being a response to immediate demands. Other rationales encompass improved data gathering, fortified resource oversight, elevated customer experiences, amplified profits, enhanced agility, heightened productivity, insights driven by data about customers, and bolstered security.",
    state: "close",
  },
  {
    id: 4,
    heading: "What does agile software development entail?",
    paragraph:
      "Agile software development focuses on optimizing processes and creating minimum viable products (MVPs) that undergo multiple iterations before reaching completion. Continuous feedback is collected and integrated. This approach is also adopted to enhance quality, meet customer satisfaction, achieve greater control, enhance flexibility, minimize risks, and more.",
    state: "close",
  },
  {
    id: 5,
    heading: "Which technologies facilitate digital transformation?",
    paragraph:
      "Technology and digital transformation are interconnected. Technology serves as a catalyst that backs the business motives behind digital transformation, such as employing real-time data for operational workflows and enhancing the customer journey. Key technological trends that expedite the pace of digital transformation in business encompass mobility, cloud computing, blockchain, augmented reality, API-driven integrations, machine learning, IoT, and more.",
    state: "close",
  },
  {
    id: 6,
    heading: "What are the steps to establish a software development team?",
    paragraph:
      "We present a three-step approach for forming a software development team: selecting software developers, seamlessly integrating the team into your projects, and enabling scalable operations as needed.",
    state: "close",
  },
  {
    id: 7,
    heading: "What is the cycle of software development?",
    paragraph:
      "The software development life cycle denotes the organized procedure employed to conceive, create, and assess software of excellent quality. Our software development life cycle encompasses six stages: initiation with a software discovery workshop, software development consultation, wireframing and design, actual development, testing, and eventual deployment.",
    state: "close",
  },
  {
    id: 8,
    heading: "What are the key quadrants of digital transformation?",
    paragraph: "The main focal points of digital transformation include: ",
    bulletArr: [
      "Process transformation",
      "Business model transformation",
      "Domain transformation",
      "Cultural transformation",
    ],
    state: "close",
  },
  {
    id: 9,
    heading:
      "How does cloud technology support the process of digital transformation?",
    paragraph:
      "The cloud is instrumental in achieving effective digital transformation. Its capacity to provide adaptable, readily accessible resources is essential for sustaining novel digital business ventures. A cloud-based architecture empowers organizations to dynamically expand their infrastructure to match evolving business objectives, mitigating the hazards of underutilized IT assets that hindered prior investments in modern digital services.",
    state: "close",
  },
  {
    id: 10,
    heading: "What is the process of outsourcing software development?",
    paragraph:
      "When considering outsourcing software development, a sequence of actions should be taken. Begin by outlining your objectives, creating comprehensive work scope documentation, specifying your preferred technology stack, selecting a development company, and effectively overseeing project scope, budget, and timelines. Our company stands as a frontrunner in providing top-tier software development outsourcing services. By collaborating with us, you gain access to the expertise of the top 1% of developers, ensuring exceptional outcomes.",
    state: "close",
  },
  {
    id: 11,
    heading:
      "What are the reasons behind the failure of digital transformations?",
    paragraph:
      "Even though digital transformation is crucial for business survival, many companies encounter failures. The primary factors contributing to these failures are:",
    bulletArr: [
        "Absence of a clear digital strategy and goals",
        "Inadequate change management",
        "Compromising internet security concerns",
        "Insufficient commitment to the transformation process",
      ],
    state: "close",
  },
  {
    id: 12,
    heading: "How much does digital transformation cost?",
    paragraph:
      "The expense of digital transformation varies based on factors such as the type of business, its objectives, capabilities, and requirements. Nonetheless, the investment is dwarfed by its advantages. At Capital Numbers, we present competitive pricing for our digital solutions. Reach out to our digital transformation consultants for comprehensive information.",
    state: "close",
  },
];

const AccordionComponent = () => {
  const [openAcc, setOpenAcc] = useState(null);
  const [accordionForm, setAccordionForm] = useState([]);

  useEffect(() => {
    setAccordionForm(accordionItems);
  }, []);
  // const handleOpenAcc = (slug) => setOpenAcc(slug);

  const handleOpenAcc = (item) => {
    let tempObj = null;
    let indexObj = accordionForm?.findIndex((obj, ind) => item?.id == obj?.id);
    tempObj = accordionForm?.find((obj, ind) => item?.id == obj?.id);
    if (tempObj != null) {
      if (tempObj?.state == "open") {
        tempObj.state = "close";
      } else {
        tempObj.state = "open";
      }
    }
    accordionForm.splice(indexObj, 1, tempObj);
    setAccordionForm([...accordionForm]);
  };

  const customAnimation = {
    mount: { scale: 1 },
    unmount: { scale: 0.9 },
  };

  return (
    <Fragment>
      <div className="">
        {accordionForm.map((item, ind) => {
          return (
            <Accordion
              key={ind}
              open={item?.state == "open" ? true : false}
              animate={customAnimation}
              className={`border-b-2 px-8 ${
                item?.state == "open" &&
                "border-l-4 border-l-[#0641A2] bg-[#E3E3E380]"
              }`}
            >
              <AccordionHeader
                onClick={() => handleOpenAcc(item)}
                className={`${item?.state == "open" && "pb-0"}`}
              >
                <div className="flex items-center gap-x-4">
                  <img
                    className=""
                    alt=""
                    src={
                      item?.state == "open"
                        ? AccordionItemOpen
                        : AccordionItemClose
                    }
                  />
                  <p className="text-black text-[16px]">{item?.heading}</p>
                </div>
              </AccordionHeader>
              <AccordionBody
                style={{
                  display: item?.state == "open" ? "flex" : "none",
                  paddingLeft: "24px",
                  width: "100%",
                }}
              >
                <div className="flex flex-col w-full gap-y-2">
                {item?.paragraph && item?.paragraph}
                
                  {item?.bulletArr?.length > 0 && (
                    <ul
                      role="list"
                      className="grid grid-cols-1 gap-1 list-disc list-inside text-[#767C93] px-2"
                    >
                      {item?.bulletArr?.map((nestedItem, index) => {
                        return <li key={index}>{nestedItem}</li>;
                      })}
                    </ul>
                  )}
                </div>
              </AccordionBody>
            </Accordion>
          );
        })}
      </div>
    </Fragment>
  );
};

export default AccordionComponent;
