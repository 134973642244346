import React, { Fragment, useState } from "react";
import Logo from "../../assets/img/logo.svg";
import { Link } from "react-router-dom";
import DialogComponent from "../common/Dialog";
import { scroller } from "react-scroll";
import DrawerComponent from "../common/DrawerComponent";
import { navigationList } from "./data";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);

  const scrollToSection = (sectionId) => {
    scroller.scrollTo(sectionId, {
      duration: 100, // Adjust the duration as needed
      delay: 10,
      smooth: true,
    });
  };
  return (
    <Fragment>
      <DrawerComponent open={openDrawer} setOpen={setOpenDrawer} drawerList={navigationList} scrollToSection={scrollToSection}/>
      <DialogComponent isOpen={isOpen} setIsOpen={setIsOpen} />
      <div id="navbar" className="bg-white sticky top-0 container mx-auto z-[9]">
        <nav className="hidden lg:block bg-white px-6">
          <div className="flex justify-between my-4">
            <div className="flex">
              <div className="flex flex-shrink-0 items-center">
                <Link to={"/"}>
                  <img className="h-8 w-auto" src={Logo} alt="Your Company" />
                </Link>
              </div>
            </div>
            <div className="flex items-center bg-blue-es p-2 rounded-lg">
              <div className="hidden md:ml-6 md:flex">
                {/* <!-- Current: "border-indigo-500 text-gray-900", Default: "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700" --> */}
                <Link
                  to={"/"}
                  className="text-sm uppercase items-center xl:px-7 lg:px-4 font-semibold py-3 rounded-lg text-black hover:bg-red-f0 hover:text-white"
                  onClick={() => scrollToSection("navbar")}
                >
                  HOME
                </Link>
                <Link
                  to={"#"}
                  className="text-sm uppercase items-center xl:px-7 lg:px-4 font-semibold py-3 rounded-lg text-black hover:bg-red-f0 hover:text-white"
                  onClick={() => scrollToSection("services")}
                >
                  Services
                </Link>
                <Link
                  to={"#"}
                  className="text-sm uppercase items-center xl:px-7 lg:px-4 font-semibold py-3 rounded-lg text-black hover:bg-red-f0 hover:text-white"
                  onClick={() => scrollToSection("about")}
                >
                  ABOUT US
                </Link>
                <Link
                  to={"#"}
                  className="text-sm uppercase items-center xl:px-7 lg:px-4 font-semibold py-3 rounded-lg text-black hover:bg-red-f0 hover:text-white"
                  onClick={() => scrollToSection("portfolio")}
                >
                  Portfolio
                </Link>
                <Link
                  to={"#"}
                  className="text-sm uppercase items-center xl:px-7 lg:px-4 font-semibold py-3 rounded-lg text-black hover:bg-red-f0 hover:text-white"
                  onClick={() => scrollToSection("technologies")}
                >
                  TECHNOLOGIES
                </Link>
                <Link
                  to={"#"}
                  className="text-sm uppercase items-center xl:px-7 lg:px-4 font-semibold py-3 rounded-lg text-black hover:bg-red-f0 hover:text-white"
                >
                  CONTACT US
                </Link>
                <Link
                  to={"#"}
                  className="text-sm uppercase rounded-lg items-center bg-red-f0 xl:px-7 lg:px-4 font-semibold py-3 text-white hover:bg-red-f0 hover:text-white"
                  onClick={() => setIsOpen(true)}
                >
                  BOOK A CALL
                </Link>
              </div>
            </div>
          </div>
        </nav>

        <nav className="md:flex lg:hidden w-full">
          <div className="w-full p-4 flex justify-between items-center">
            <div className="w-max" onClick={() => setOpenDrawer(true)}>
              <svg
                className="block h-10 w-10"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                />
              </svg>
            </div>
            <Link to={"/"}>
              <img className="h-8 w-auto" src={Logo} alt="Your Company" />
            </Link>
            <Link
              to={"#"}
              className="text-sm uppercase rounded-lg items-center bg-red-f0 px-7 font-semibold py-3 text-white hover:bg-red-f0 hover:text-white"
              onClick={() => setIsOpen(true)}
            >
              BOOK A CALL
            </Link>
          </div>
        </nav>
      </div>
    </Fragment>
  );
};

export default Navbar;
