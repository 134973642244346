import React from "react";
import HeadingCurve from '../../assets/img/heading-curve.svg'

const DynamicTradingLogo = ({beforeBr, afterBr, textColor = ''}) => {
  return (
    <div className="flex gap-1 flex-col">
      <p className="text-blue-1f font-semibold text-sm">Dynamic Trading </p>
      <img className="w-12" src={HeadingCurve} alt="" />
      <h1 className={`2xl:text-5xl 2xl:leading-16 xl:text-4xl xl:leading-12 font-bold ${textColor != '' ? `text-[${textColor}]` : 'text-blue-26'}`}>
        {beforeBr} <br /> {afterBr}
      </h1>
    </div>
  );
};

export default DynamicTradingLogo;
