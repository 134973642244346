import React from "react";
import PortfolioCard from "../common/PortfolioCard";
import PortfolioImg1 from '../../assets/img/portfolio/p1.png'
import PortfolioImg2 from '../../assets/img/portfolio/p2.png'
import PortfolioImg3 from '../../assets/img/portfolio/p3.png'
import PortfolioImg4 from '../../assets/img/portfolio/p4.png'
import PortfolioImg5 from '../../assets/img/portfolio/p5.png'
import PortfolioImg6 from '../../assets/img/portfolio/p6.png'


const portfolioCards = [
  {
    id: 1,
    logo: PortfolioImg1,
    title: "Streamer for Chromecast TVs",
    description: `Cast Streamer is the ultimate streaming app available for Chromecast, Google Home and any Home TV with Chromecast built-in!`,
    link: 'https://apps.apple.com/app/id1551241504'
  },
  {
    id: 2,
    logo: PortfolioImg2,
    title: "Secret Photo Vault- Photo Lock",
    description: `Photo Lock will not only keep your private photos secure and hidden from intrusive eyes but also provide a personal storage space. Now you don't have to worry about your phone's storage data as you can save unlimited photos & videos on our app's cloud storage!`,
    link: 'https://apps.apple.com/pk/app/secret-photo-vault-photo-lock/id1586581420'
  },
  {
    id: 3,
    logo: PortfolioImg3,
    title: "Portfolio Website in HTML/CSS/JS",
    description: `Portfolio website for Sehr`,
    link: 'https://tutorage.uk/'

  },
  {
    id: 4,
    logo: PortfolioImg4,
    title: "Woodart Wocommerce Website",
    description: `Premium online store to buy office furniture`,
    link: 'https://woodart.com.pk/'

  },
  {
    id: 5,
    logo: PortfolioImg5,
    title: "iCast Labs",
    description: `Ultimate Streams for every TV`,
    link: 'http://icastlabs.com/'

  },
  {
    id: 6,
    logo: PortfolioImg6,
    title: "Edible Arrangements",
    description: `Finding the perfect gift has never been easier than now with the Edible Arrangements iOS app! Send your favorite Edible gifts with just a few simple taps. From delicious chocolate covered strawberries, fruit bouquets and gift baskets overflowing with gourmet chocolate dipped fruit, the Edible app is the perfect gifting solution.`,
    link: 'https://apps.apple.com/us/app/edible-arrangements/id1142212779'

  },
];

const Portfolio = () => {
  return (
    <section id="portfolio" className="flex flex-col bg-[#F9F7FE] px-4 sm:px-6 lg:px-8 py-16">
      <div className="container mx-auto">
        <p className="text-sm font-bold uppercase text-[#001854]">Latest Products and Applications</p>
        <p className="text-5xl font-bold text-[#001854] pt-2">Our Portfolio</p>
        
        <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 pt-10">
          {portfolioCards?.map((item, ind) => {
            return <PortfolioCard key={ind} item={item} />;
          })}
        </div>
      </div>
    </section>
  );
};

export default Portfolio;
