import React, { useState } from "react";
import Hero from "../../Components/hero";
import DialogComponent from "../../Components/common/Dialog";

const Services = () => {
  const [openDialog, setOpenDialog] = useState(false);
  return (
    <>
      <DialogComponent isOpen={openDialog} setIsOpen={setOpenDialog} />
      <div id={'services'} className="flex flex-col">
        <Hero callFrom={'services'}/>
      </div>
    </>
  );
};

export default Services;
