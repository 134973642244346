import React from 'react'

const PortfolioCard = ({item}) => {
    const linkHandler = (item) =>{
        window.open(item?.link, '_blank');
    }
    return (
        <div className='flex flex-col gap-y-3 p-4 min-w-[315px] drop-shadow-md bg-white rounded-sm'>
            <img className="pt-2 min-w-[293px] h-[220]" src={item?.logo} alt="" />
            <p className='text-black font-bold text-[20px]'>{item?.title}</p>
            <p className='text-black text-[14px]'>{item?.description}</p>
            <div>
            <button className="rounded-md px-4 py-1 bg-gradient-to-r from-[#00DEFF] to-[#007FFF] text-white text-[14px] font-bold" onClick={()=> linkHandler(item)}>Details</button>
            </div>
        </div>
    )
}

export default PortfolioCard