import React, { useEffect, useState } from "react";
import TechCard from "../common/TechCard";
import { techCards } from "./data";
import HeadingCurve from "../../assets/img/heading-curve.svg";

const DigitalEngineering = () => {
  const [techCardsArr, setTechCardsArray] = useState([]);
  useEffect(() => {
    if (techCards?.length > 0) {
      setTechCardsArray([...techCards]);
    }
  }, []);
  return (
    <div id='services' className="container mx-auto">
      <div className="w-full flex flex-col justify-center items-start gap-y-4 px-4 sm:px-6 lg:px-8 py-12">
        <div className="flex gap-1 flex-col">
          <p className="text-blue-1f font-semibold text-sm">Dynamic Trading </p>
          <img className="w-12" src={HeadingCurve} alt="" />
          <h1 className="2xl:text-5xl 2xl:leading-16 xl:text-4xl xl:leading-12 font-bold text-blue-26">
            Your Digital Engineering <br /> and IT Talent Partner
          </h1>
        </div>
        <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 pt-10">
          {techCardsArr?.map((item, ind) => {
            return <TechCard key={ind} item={item} />;
          })}
        </div>
      </div>
    </div>
  );
};

export default DigitalEngineering;
