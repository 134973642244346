import Icon1 from '../../assets/icons/about/Group 392.svg'
import Icon2 from '../../assets/icons/about/Group 384.svg'
import Icon3 from '../../assets/icons/about/Group 389.svg'
import Icon4 from '../../assets/icons/about/about_icon4.svg'

export const aboutGuidelines = [
    {slug: 'respect', title: 'Respect', icon: Icon1, description: 'We create a culture of mutual appreciation, regard and value. In everything we do, we act towards others with dignity while celebrating diversity and acceptance.'},
    {slug: 'integrity', title: 'Integrity', icon: Icon2, description: 'Our integrity ensures our credibility. Honor your commitments and take ownership of your actions.Our words and deeds are true and reliable'},
    {slug: 'transparency', title: 'Transparency', icon: Icon3, description: 'Transparency fosters trust and collaboration. We maintain and encourage straightforward dialogue with our leadership, employees, clients, and stakeholders.'},
    {slug: 'excellence', title: 'Excellence', icon: Icon4, description: 'We strive for excellence in all that we do. Each employee’s contribution Is vital towards delivering exceptional customer service experience to our clients.'},
]