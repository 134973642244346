import React from "react";
import FacebookIcon from "../../assets/icons/footer/facebook.svg";
import LinkedinIcon from "../../assets/icons/footer/linkedin.svg";
import TwitterIcon from "../../assets/icons/footer/twitter.svg";
import YoutubeIcon from "../../assets/icons/footer/youtube.svg";
import InstagramIcon from "../../assets/icons/footer/instagram.svg";
import Logo from "../../assets/img/logo.svg";

const Footer = () => {
  return (
    <div className="flex flex-col gap-y-8 px-4 sm:px-6 lg:px-8 py-10 w-full bg-[#001E37]">
      <div className="container mx-auto divide-y">
        <div className="flex flex-col gap-y-8 pb-8">
          <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-4 place-items-start gap-12 bg-[#022651] p-6">
            <div className="flex flex-col gap-y-5">
              <img src={Logo} alt="" className="w-[156px] h-[35.98px]" />
              <p className="text-[12px] text-white">
                We believe IT Services are a critical necessity for businesses.
                Our mission is to provide the highest quality of IT Services to
                our clients so they can focus on increasing profitability and
                efficiency.{" "}
              </p>
              <div className="flex items-center gap-x-3">
                <div className="relative inline-flex items-center justify-center w-[34px] h-[34px] p-2 overflow-hidden bg-white rounded-full">
                  <img src={FacebookIcon} alt="" className="" />
                </div>
                <div className="relative inline-flex items-center justify-center w-[34px] h-[34px] p-2 overflow-hidden bg-white rounded-full">
                  <img src={LinkedinIcon} alt="" className="" />
                </div>
                <div className="relative inline-flex items-center justify-center w-[34px] h-[34px] p-2 overflow-hidden bg-white rounded-full">
                  <img src={TwitterIcon} alt="" className="" />
                </div>
                <div className="relative inline-flex items-center justify-center w-[34px] h-[34px] p-2 overflow-hidden bg-white rounded-full">
                  <img src={YoutubeIcon} alt="" className="" />
                </div>
                <div className="relative inline-flex items-center justify-center w-[34px] h-[34px] p-2 overflow-hidden bg-white rounded-full">
                  <img src={InstagramIcon} alt="" className="" />
                </div>
              </div>
            </div>
            <div className="lg:pl-10 flex flex-col gap-y-4 text-white pt-3">
              <p className="text-[14px] font-bold ">Quick Links</p>
              <div className="flex flex-col gap-y-2">
                <p className="text-[12px]">Home</p>
                <p className="text-[12px]">Services</p>
                <p className="text-[12px]">Portfolio</p>
                <p className="text-[12px]">Blogs</p>
                <p className="text-[12px]">Careers</p>
              </div>
            </div>
            <div className="flex flex-col gap-y-4 text-white pt-3">
              <p className="text-[14px] font-bold ">Office Address</p>
              <div className="flex flex-col gap-y-2">
                <p className="text-[12px]">13-D Commercial, Valencia, Lahore,</p>
                <p className="text-[12px]">
                  Phone: <strong>+923450155553</strong>
                </p>
                <p className="text-[12px]">
                  Email: <strong>info@devtechhub.com</strong>
                </p>
              </div>
            </div>
            <div className="flex flex-col gap-y-4 text-white pt-3">
              <p className="text-[14px] font-bold ">Newsletter</p>
              <p className="text-[11px]">
                Reference site about Lorem Ipsum, giving information on its
                origins,
              </p>
              <label className="relative flex">
                <input
                  className="text-black placeholder:italic placeholder:text-slate-400 block bg-white w-full border border-slate-300 rounded-md py-[13.5px] px-2  pr-16 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm"
                  placeholder="Search for anything..."
                  type="text"
                  name="search"
                />
                <button className="absolute right-0 text-[11px] bg-gradient-to-r from-[#00DEFF] to-[#007FFF] text-white p-4 rounded-md">
                  SEND
                </button>
              </label>
            </div>
          </div>

        </div>
        <div className="flex justify-center pt-8">
          <p className="text-[12px] font-medium text-white">
            © 2022 Copyright DevTechHub. All Rights Reserved
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
