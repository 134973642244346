import React from 'react'
import AccordionComponent from '../common/AccordionComp'

const FrequentlyAskedQuestions = () => {
  return (
    <div className='container mx-auto'>
      <div className='flex flex-col gap-y-4 px-4 sm:px-6 lg:px-8 pt-0'>
          <p className='text-4xl font-bold text-[#1B2C3B]'>Frequently Asked Questions</p>
          {/* <p className='text-[14px] text-[#5C6972] w-[30%] leading-[16.25px]'>We create a culture of mutual appreciation, regard and value. In everything we do, we act towards others with dignity while celebrating diversity and acceptance.</p> */}
          <div className='py-10'>
          <AccordionComponent/>
          </div>
      </div>
    </div>
  )
}

export default FrequentlyAskedQuestions